<template>
    <div>
      <headerSection></headerSection>
  
      <div id="main__content" class="">
        <div class="x-main-container">
          <div class="x-main-content">
              <div class="x-promotion-index" :style="{ 'background-image': `url(${backgroundImage})`,'background-position': 'center','background-repeat': 'no-repeat','background-size': 'cover'}">
                  <div class="container">
                      <div class="row">
                          <div class="col-12">
                              <div class="x-page-title-component -long-support">
                                  <div class="-inner-wrapper">
                                      <h1 class="-title">ของรางวัลมีให้แลกมากมาย ที่ IMFAST28</h1>
                                  </div>
                              </div>
                              <p class="-description">แลกได้เมื่อมีเครดิตค้างต่ำกว่า 5 บ.<br>
สามารถนำไปเล่นได้เฉพาะเกม และคาสิโน<br>
ไม่สามารถนำไปเล่นใน UFA<br>
ถอนได้เมื่อทำยอด 3 เท่า
ถอนได้สูงสุด 3 เท่า<br>
โบนัสไม่สามารถถอนเป็นเงินได้ ถูกดึงกลับเมื่อมีการแจ้งถอน<br>
สิ้นสุดเงื่อนไขเมื่อมีเครดิตคงเหลือต่ำกว่า 5 บ.</p>
                          </div>
                      </div>
                  </div>
                  <div class="container">
                      <div class="row -row-wrapper x-divine-gutter">
                          <div class="col-12 col-md-6 mt-3 -col-wrapper x-divine-gutter animated fadeInUp" style="padding-top: 10px;" data-animatable="fadeInUp" data-delay="100" v-for="(item,index) in rewards" :key="index">
                              <a href="#0" @click="conFirmRedeem(item)" class="x-promotion-list-item " style="padding-left: 60px;width:80%">
                              <picture>
                                  <source type="image/png" :srcset="item.image">
                                  <img class="-cover-img img-fluid" :alt="item.name" :style="checkStyle(item)" width="200" height="200" :src="item.image" >
                              </picture>
                              </a>
                          </div>
                      </div>
                  </div>
                  <div class="container mt-4">
                      <div class="row">
                          <div class="col-12">
                              <h2 class="-sub-title"><a href="https://imfast28.com">IMFAST28</a>&nbsp;คาสิโนชั้นนำ รวมแบรนด์ดัง มีทุกค่าย รับง่าย ถอนไว</h2>
                              <p class="-description">
                                  ศูนย์รวมแบรนด์ที่ดีทีสุดและเกมส์คาสิโนทุกเกมส์ไว้ที่นี่ที่เดียว อาทิ UFA IMI AMB EDMBET และ Betflix เฉพาะครอบครัว IMFAST28 เท่านั้น
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
  
  
            <footerSection></footerSection>
          </div>
        </div>
  
        <div class="x-right-sidebar-container"></div>
  
      </div>
    </div>
  </template>
  
<script>
import firebase from 'firebase/app'
import moment from 'moment'
const axios = require('axios').default
export default {
  name: 'Home',
  components: {
    headerSection: () => import('./Header.vue'),
    footerSection: () => import('./Footer.vue')
  },
  mounted () {
    const width = screen.width
    if(width <= 480)
      this.backgroundImage = require('../assets/images/Shiba-BG.jpg')
    else
      this.backgroundImage = require('../assets/images/Shiba-BG.jpg')
    // Bonn.boots.push(function() {
    //   setTimeout(function() {
    //     $('#bankInfoModal').modal('show');
    //   }, 500);
    // });
    this.$store.dispatch('user/getRewards').catch(err => { console.error(err) })
    // this.checkAnoucement()
  },
  computed: {
    sites_credit () {
      return this.$store.state.user.sites_credit
    },
    user () {
      return this.$store.state.user.user
    },
    rewards () {
      return this.$store.state.user.rewards
    },
    swiper () {
      return this.$refs.mySwiper.$swiper
    },
    lastbonus () {
      return this.$store.state.user.lastbonus
    }
  },
  data () {
    return {
      continueCheck: false,
      receiveAnounceMent: false,
      anoucement: {},
      balance: 0,
      fa_spin: false,
      backgroundImage: '',
      disabled: false
    }
  },
  methods: {
    checkStyle (item) {
      if ((!this.user || !this.user.key || !this.user.coin || Number(this.user.coin) < item.point)) {
        return 'border-radius:0px;-webkit-filter: grayscale(100%);filter: grayscale(100%);'
      }
    },
    async conFirmRedeem (item) {
      if(this.disabled) return
      this.disabled = true
      this.$store.commit('user/SET_LOADING', true)
      this.$store.dispatch('user/moveCreditToWallet').finally(async(res) => { 
        this.$store.commit('user/SET_LOADING', false)
        if (item.turn && Number(item.turn) > 0 && this.user && Number(this.user.credit) > 5) {
          this.$swal({ icon: 'error', title: 'Error!', text: 'ท่านมีมากกว่า 5 เครดิต ไม่สามารถเล่นได้!!!' })
          return false
        }
        let check = false;
        await firebase.firestore().collection('history').where('userkey','==',this.user.key).where('status','==','กำลังดำเนินการ').where('type','==','ถอน').limit(1).get().then((result)=>{
          if(result.size){
            check = true;
          }
        }); 
        if(check){
          this.$swal({ icon: 'error', title: 'Error!', text: 'ท่านมีรายการถอนเงินที่กำลังดำเนินการอยู่ กรุณารอการตรวจสอบ' })
          return false
        }
        if (this.user.coin && Number(this.user.coin) >= Number(item.point)) {
          this.$confirm(
            {
              message: `ต้องการใช้เหรียญ ${item.name} เล่นเฉพาะเกม หรือคาสิโน
ทำยอด ${item.turn} เท่า ถอนสูงสุด ${item.maxwithdraw} เท่า
ไม่สามารถถอนโบนัสได้`,
              button: {
                yes: 'ยินยัน',
                no: 'ยกเลิก'
              },
              callback: confirm => {
                if (confirm) {
                  this.$store.commit('user/SET_LOADING', true)
                  setTimeout(() => {
                    firebase.firestore().collection('redeem').add({
                      userkey: this.user.key,
                      code: this.user.code,
                      reward: item.key,
                      type: item.type,
                      credit: (item.credit) ? item.credit : '',
                      point: item.point,
                      status: false,
                      createdate: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
                    }).then((result) => {
                      axios({
                        method: 'post',
                        url: 'https://api.imfast28.com/qmovetowallet/redeem',
                        data: {
                          code: this.user.code,
                          key: result.id
                        }
                      })
                      this.$store.commit('user/SET_LOADING', false)
                    })
                  }, Math.floor(Math.random() * 3000))
                }
              }
            }
          )
        }
      }).catch(err => { console.error(err) })
      setTimeout(() => {
        this.disabled = false
      }, 2000)
    },
    async redeem (item) {
      if (!(!this.user || !this.user.key || !this.user.coin || Number(this.user.coin) < item.point)) {
        if (item.type === 'credit') {
          this.$store.dispatch('user/refreshCreditAll').finally(res => {
            if (this.sites_credit.credit >= 5) {
              this.$swal({ icon: 'error', title: 'แลกเครดิต', html: 'ไม่สามารถแลกเครดิตได้เนื่องจากมียอดคงเหลือมากกว่า 5 บาท' })
            } else {
              this.conFirmRedeem(item)
            }
          })
        } else {
          this.conFirmRedeem(item)
        }
      }
    }
    // async moveCreditToWallet () {
    //   this.$store.commit('user/SET_LOADING', true)
    //   const user = this.getUserId()
    //   this.$store.dispatch('user/moveCreditToWallet').catch(err => { console.error(err) })
    //   this.$store.dispatch('user/getLastBonus', user).finally(res => {
    //     this.$store.commit('user/SET_LOADING', false)
    //     if (this.lastbonus.turncredit) {
    //       this.$store.commit('user/SET_ALERT_WITHDRAWALL', true)
    //     }
    //   }).catch(err => { console.error(err) })
    // },
  }
}
</script>
  <style scoped>
  @media screen and (max-width: 992px) {
      .resize {
          width: 899px;
      }
  }
  @media screen and (max-width: 600px) {
      .resize {
          width: 399px;
      }
  }
  .bg-mobile2{
    margin-right: 100px;
  }
  @media screen and (max-width: 992px) {
      .resize {
          width: 899px;
      }
  }
  @media screen and (max-width: 600px) {
      .resize {
          width: 399px;
      }
      .bg-mobile{
        background-color: #1c1c1c;
      }
      .bg-mobile2{
        margin-right: 0;
      }
  }
  .btn {
      border-radius: 25px;
      color: #fff;
      border: none;
      padding: 0.8rem 2.5rem;
  }
  .btn.-btn-redeem{background:radial-gradient(100% 100% at 50% 0,#a8ff9c 0,#4dd842 100%);box-shadow:0 2px 4px rgba(0,0,0,.12),0 1px 2px hsla(0,7.3%,92%,.24)}
  .btn.-btn-redeem:hover{background:radial-gradient(100% 100% at 50% 0,#a8ff9b 0,#33bf28 100%)}
  .btn.-btn-redeem:disabled,
  .btn.-btn-redeem[disabled]{background:radial-gradient(100% 100% at 50% 0,#cccccc 0,#9e9999 100%)}
  </style>
  